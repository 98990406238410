.steps-container{
  max-width: 500px;
  margin: 0 auto 15px;
}
/* Animations */
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  66% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  33% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  66% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Component Styles - Steps */
.steps {
  display: -webkit-box;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0 0 1rem 0;
  list-style: none;
}

.steps-count{
  text-align: center;
  span{
    text-transform: uppercase;
    font-family: 'brandon_grotesqueblack', sans-serif;
    color: $purple;
  }
}

.step {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1;
  position: relative;
  pointer-events: none;
  margin-bottom: 30px;
}
.step:not(:last-child):before, .step:not(:last-child):after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.35rem;
  content: '';
  will-change: width;
  margin-top: -2px;
}
.step:before {
  width: 100%;
  background-color: #e6e7e8;
}
.step:after {
  width: 0;
  background-color: $purple;
}
.step--complete:after {
  width: 100% !important;
  opacity: 1;
  -webkit-transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;
  transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

.step__icon {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.step--complete .step__icon:before,
.step--active .step__icon:before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: $purple;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.step--complete.step--active .step__icon {
  color: #fff;
  -webkit-transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.step--incomplete.step--active .step__icon {
  border-color: $gray;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.step--complete .step__icon {
  -webkit-animation: bounce 0.5s ease-in-out;
  animation: bounce 0.5s ease-in-out;
  background-color: #fff;
  color: #fff;
}

.step__label {
  position: absolute;
  bottom: -2rem;
  left: 50%;
  white-space: nowrap;
  margin-top: 1rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #EC008C;
  font-weight: 900;
  font-family: 'brandon_grotesque', sans-serif;
}
.step--incomplete.step--inactive .step__label {
  color: #e6e7e8;
}
.step--incomplete.step--active .step__label {
  color: #fff;
}
.step--active .step__label {
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
