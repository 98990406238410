@import "bootstrap-sass/bootstrap";
@import "fonts/avenir/avenir-font";
@import "fonts/brandon-grotesque/brandon-grotesqueblack-font";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import "colors";
@import "buttons";
@import "form";
@import "layout";
@import "left-content";
@import "form-header";
@import "progress-bar";
@import "form-header";
@import "course-path";

