@font-face {
  font-family: 'brandon_grotesqueblack';
  src: url('brandongrotesque-black-webfont.woff2') format('woff2'),
  url('brandongrotesque-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'brandon_grotesque';
  src: url('BrandonText-Regular.eot?#iefix') format('embedded-opentype'),  url('BrandonText-Regular.otf')  format('opentype'),
  url('BrandonText-Regular.woff') format('woff'), url('BrandonText-Regular.ttf')  format('truetype'), url('BrandonText-Regular.svg#BrandonText-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
