.form-header{
  text-align: center;
  max-width: 410px;
  margin: 30px auto 40px;
}
.form-header--title{
  font-family: 'brandon_grotesqueblack', sans-serif;
  color: $purple;
  font-size: 30px;
  line-height: 25px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.form-header--desc{
  line-height: 19px;
  font-size: 15px;
}
