.course-path{
  background-color: #fff;
  position: relative;
  margin: 30px auto;

  @media screen and (max-width: 640px) {
    padding-top: 30px;
  }

  .course-path--container{
    position: relative;
    max-width: 850px;
    width: 60%;
    @media screen and (max-width: 768px){
      width: 100%;
    }
  }

  .course-path--bg{
    width: 100%;
  }

  .course-path--icon{
    position: absolute;
    cursor: pointer;
    transition: transform 0.2s ease;
    @media screen and (min-width: 768px){
      &:hover{
        transform: translateY(-15px);
      }
    }
    &.active span,
    &:hover span{
      color: $purple;
    }
    span{
      transition: color 0.2s ease;

      position: absolute;
      font-family: 'brandon_grotesqueblack', sans-serif;
      color: #DC9A20;
      line-height: 22px;
      font-size: 21px;
      text-align: center;
      @media screen and (max-width: 768px){
        font-size: 13px;
        line-height: 16px;
      }
    }
    img{
      width: 55px;
      @media screen and (max-width: 640px){
        width: 30px;
      }
    }
  }
  #icon1{
    left: 5%;
    top: 5%;
    span{
      bottom: -40px;
      left: -8px;
    }
  }
  #icon2{
    left: 26%;
    top: 13%;
    span{
      bottom: -55px;
      width: 160px;
      left: -105px;
      @media screen and (max-width: 640px) {
        width: 100px;
        top: -38px;
        left: -35px;
      }
    }
  }
  #icon3{
    left: 41%;
    top: 17%;
    span{
      bottom: 90px;
      width: 160px;
      left: 30px;
      @media screen and (max-width: 640px){
        bottom: 35px;
        width: 100px;
        left: 20px;
      }
    }
  }
  #icon4{
    left: 70%;
    top: 28%;
    span{
      width: 100px;
      top: -15px;
      left: 55px;
      @media screen and (max-width: 640px) {
        width: 70px;
        top: -15px;
        left: 25px;
      }
    }
  }
  #icon5{
    left: 36%;
    top: 42%;
    span{
      width: 100px;
      top: 40px;
      left: -105px;
      @media screen and (max-width: 640px) {
        width: 100px;
        top: 3px;
        left: -85px;
      }
    }
  }
  #icon6{
    left: 22%;
    top: 62%;
    span{
      width: 100px;
      top: 40px;
      left: -115px;
      @media screen and (max-width: 640px) {
        width: 80px;
        top: 30px;
        left: -65px;
      }
    }
  }
  #icon7{
    right: 28%;
    bottom: 7%;
    span{
      left: 60px;
      @media screen and (max-width: 640px) {
        left: 40px;
        top: -15px;
      }
    }
  }
}

.course-path-desc{
  position: absolute;
  max-width: 545px;
  width: 42%;
  right: 30px;
  top: 120px;
  @media screen and (max-width: 1120px){
    width: 32%;
    top: 20px;
  }
  @media screen and (max-width: 768px){
    position: initial;
    width: 100%;
    padding: 10px;
  }
  h2{
    color: $purple;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 21px;
  }
  h3{
    color: $purple;
    line-height: 15px;
    font-weight: 500;
    font-size: 19px;
  }
  p{
    line-height: 16px;
  }
}

.video-description{
  background: #fff;
  padding: 45px 0;
  h2{
    color: #febc44;
    @media screen and (max-width: 768px){
      font-size: 16px;
      margin-top: 15px;
    }
  }
  p{
    line-height: 19px;
  }
  .video-description--video{
    max-width: 100%;
  }
}