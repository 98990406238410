body{
  font-size: 14px;
  font-family: 'AvenirLTStd-Book', sans-serif;
}
#main{
  background-color: #EFF1F5;
  padding: 25px 10px;
  @media (max-width: 480px) {
    padding: 15px 0;
  }
  min-height: 100vh;
  & > div{
    min-height: 100vh;
    max-width: 1366px;
  }
}
.form-container{
  background-color: #fff;
  height: 100%;
  padding: 35px 5px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #dcdcdc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d6d6d6;
    border-radius: 10px;
  }
}
.small-container{
  max-width: 530px;
  margin: 0 auto;
  height: 100%;
}
.mid-container{
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
}

.c-card {
  text-align: center;
  .c-card--container{
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(194, 194, 194, 0.5);
    border-radius: 5px;
  }
  .c-card--title{
    color: $green-eac;
  }
  .c-map{
    height:calc(100% - 38px);
    iframe{
      border: 1px solid rgba(42, 186, 181, 0.32);
      border-radius: 5px;
    }
  }
}

.c-modal{
  text-align: center;
  padding: 20px 10px;
  @media screen and (min-width: 720px){
    width: 350px;
  }
  h4{
    color: $purple;
    font-family: 'brandon_grotesqueblack', sans-serif;
    font-size: 35px;
    line-height: 30px;
    margin: 15px 0;
    font-weight: 900;
  }
  p{
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 35px;
  }
}
.ReactModal__Overlay{
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 1;
}

.parent-card{
  border: 1px solid rgba(194, 194, 194, 0.5);
  border-radius: 5px;
  position: relative;
  padding: 12px;
  margin-bottom: 15px;

  table{
    table-layout: fixed;
    width: 100%;
    td{
      width: 50%;
    }
  }

  .close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff url("../images/icons/icon-close.svg");
    width: 12px;
    height: 12px;
    border: none;
  }
}
.form-container{
  position: relative;
  .steps-container--overlay{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-section{
  text-align: center;
  h3{
    font-size: 14px;
    color: $green-eac;
    font-weight: 400;
  }
}

.sweet-alert h2{
  color: $green-eac;
  max-width: 300px;
  margin: 0 auto;
}
.sweet-alert .sa-icon.sa-success .sa-line{
  background-color: #707070;
}
