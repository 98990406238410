.DayPicker {
  font-size: 0.9rem;
  border: 1px solid rgba(194, 194, 194, 0.5);
  border-radius: 5px;
  color: grey;
  padding: 10px;
  width: 100%;
}
.DayPicker-Day {
  padding: 0 1em;
  border-radius: 3px !important;
  @media (max-width: 768px) {
    padding: 0 0.8em;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
  background-color: #CD9F00;
  color: #F0F8FF;
  border-radius: 3px;
}



.DayPicker-Month{
  margin: 0;
}

.DayPicker-wrapper{
  padding-bottom: 0;
}
.DayPicker-Caption{
  margin-bottom: 0;
}
.DayPicker-NavButton {
  top: 0.5em;
  right: 0.5em;
}
