button{
  outline: none !important;
}
.btn{
  padding: 10px;
  min-width: 200px;
  border: none;
  color: #ffffff;
  border-radius: 5px;

  &:disabled{
    opacity: .7;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    min-width: 120px;
  }

  &.green{
    background-color: $green;
  }
  &.purple{
    background-color: $purple;
  }
  &.orange{
    background-color: $orange-eac;
  }
  &.orange-border{
    border: 1px solid $orange-eac;
    background-color: #fff;
    color: $orange-eac;
  }
  &.purple-border{
    border: 1px solid $purple;
    background-color: #fff;
    color: $purple;
  }
}

.btn-add-parent{
  border: none;
  color: $green-eac;
  font-size: 14px;
  background: transparent url("../images/icons/icon-add.svg") left center no-repeat;
  padding-left: 47px;
  line-height: 47px;
  padding-bottom: 4px;
}

.btn-download{
  position: fixed;
  right: -70px;
  top: 40%;
  transform: rotate(90deg);
  z-index: 10;
  @media (max-width: 768px) {
    padding: 0 15px;
    right: -70px;
  }
}