.c-radio {
  input {
    opacity: 0;
    width: 1px;
    height: 1px;
    float: left;
    margin-top: 25px;
  }

  label {
    position: relative;
    padding-left: 30px;
  }

  input + label:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid $gray;
    border-radius: 50%;
    top: 4px;
    left: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $purple;
    border-radius: 50%;
    top: 9px;
    left: 5px;
  }
}

.form-control {
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  border-color: rgba(194, 194, 194, 0.5);
  font-size: 14px;
}

.time-input{
  border: 1px solid #eee;
  border-radius: 5px;
  height: 50px;
  outline: none;
  padding: 0 15px;
}

select.form-control {
  background: #fff url("../images/icons/icon-select-arrow.png") no-repeat right 15px center;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }
}

// upload fields

.upload-field {
  border: 1px dashed #D8DADA;
  border-radius: 15px;
  padding: 15px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &.uploading {
    justify-content: center;
    min-height: 235px;
  }

  .upload-field-remove {
    position: absolute;
    right: 15px;
    top: 15px;
    background: $green-eac;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;

    & > svg {
      color: #ffffff;
      width: 22px;
      height: 22px;
    }
  }

  label {
    cursor: pointer;
  }

  .upload-field--name {
    color: $orange-eac;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    padding-top: 6px;
  }

  .upload-field--file {
    color: #828282;
    font-size: 10px;
    font-weight: 700;
    word-break: break-all;
  }

  input {
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .btn {
    align-self: center;
    min-width: auto;
    padding: 5px 35px;
  }
}

.DayPickerInput {
  width: 100%;

  input {
    background: #fff url("../images/icons/icon-calendar.png") no-repeat right 15px center;
    @extend .form-control;
  }
}

input.date {
  background: #fff url("../images/icons/icon-calendar.svg") no-repeat right 15px center;
}

//input[type="date"]:before {
//  content: attr(placeholder) !important;
//}
//
//input[type="date"]:focus:before,
//input[type="date"]:valid:before {
//  display: none;
//}
//
//input[type="date"]:focus::-webkit-datetime-edit-fields-wrapper,
//input[type="date"]:valid::-webkit-datetime-edit-fields-wrapper {
//  display: block;
//}
::-webkit-datetime-edit {
  padding: 1em;
}

//::-webkit-datetime-edit-fields-wrapper { display: none } // inside text
//::-webkit-datetime-edit-text {
//  color: red;
//}
//
//::-webkit-inner-spin-button {
//  display: none;
//}
//
//::-webkit-calendar-picker-indicator {
//  opacity: 0
//}

.cndp label {
  line-height: 16px;
  font-size: 12px;
}

.form-info {
  font-size: 11px;
  line-height: 12px;
}

.country-field{
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.phone-fields {
  display: flex;
  margin-bottom: 15px;
  position: relative;
  @extend .form-control;

  .form-error {
    position: absolute;
    bottom: -30px;
    color: red;
    white-space: nowrap;
    left: 0;
  }

  .phone-number-input {
    border: none;
    flex: 4;
    display: block;
    min-width: 0;
    outline: none;
  }

  .phone-code-select {
    max-width: 70px;
    border: none;
    flex: 3;
    display: block;
    min-width: 0;
    outline: none;
    background: #fff url("../images/icons/icon-select-arrow.png") no-repeat right 15px center;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-right: 1px solid #eeeeee;
    margin-right: 10px;
    color: #495057;

    &::-ms-expand {
      display: none;
    }
  }
}

.no-background{
  background: none !important;
}
