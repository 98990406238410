.left-content{
  height: 100%;
  background: $purple;
  background-size: 100% 100%;

  .page-banner{
    position: relative;
    @media (max-width: 480px) {
      padding: 10px 12px 0 10px;
    }
    .page-banner-desc{
      padding: 25px;
      position: relative;
      @media (max-width: 768px) {
        padding: 0.5em 1em;
      }
      @media (max-width: 480px) {
        padding: 0.5em 0;
      }
      &:after{
        content: '';
        position: absolute;
        width: 70%;
        right: -15px;
        top: -28px;
        height: 36px;
        background-color: #FEBC44;
      }
      h1{
        font-family: 'brandon_grotesque', sans-serif;
        font-size: 21px;
        font-weight: 900;
        text-transform: uppercase;
        position: relative;
        color: #FEBC44;
        @media (max-width: 1350px) {
          font-size: 18px;
        }
        @media (max-width: 768px) {
          margin-left: 10px;
          font-size: 16px;
        }
      }
      p{
        font-family: 'Open Sans', sans-serif;
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: justify;
        @media (max-width: 1350px) {
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 5px;
        }
      }
    }
  }

  picture{
    display: block;
  }
}
.main-image{
  width: 100%;
}
