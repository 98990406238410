$primary-color: #ee8d38;
$gray: #e1e1e1;
$purple: #BF2172;
$green: #00a651;
$green-eac: #5CB57D;
$orange-eac: #F39200;

.c-green{
  color: $green-eac;
}

.c-purple{
  color: $purple;
}
